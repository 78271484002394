<template>
	<div class="wrapper_calculator">
		<div class="calculator">
			<div class="main_input">
				<label for="">Revenue per Month</label>
				<input type="text" v-model="revenue" readonly>
			</div>
			<div class="row_buttons">
				<v-radio-group v-model="type" row> <!-- mandatory -->
					<v-radio value="free" color="#20C4F5" :ripple="false">
						<template v-slot:label>
							<div class="checkbox_label">
								Free
							</div>
						</template>
					</v-radio>
					<v-radio value="paid" color="#20C4F5" :ripple="false">
						<template v-slot:label>
							<div class="checkbox_label">
								Paid
							</div>
						</template>
					</v-radio>
				</v-radio-group>

				<div class="material_checkbox profitable_checkbox">
					<input type="checkbox" id="profitable" v-model="profitable" @change="checkProfitable($event)">
					<label for="Techno">Profitable</label>
				</div>
			</div>

			<div class="sliders_calc">

				<!-- ADS -->
				<div class="ads" :class="{disable: type === 'paid'}">
					<div class="top">
						<div class="left"><span>Ads</span>{{data.ads.value}}</div>
						<div class="right">{{data.ads.value}}</div>
					</div>

					<v-slider
						class="'slider ads'"
						:disabled="type === 'paid'"
						v-model="data.ads.value"
						:ripple="false"
						color="#3C875A"
						thumb-color="#9AAECD"
						:min="data.ads.min"
						:max="data.ads.max"
						:step="data.ads.step"
					></v-slider>

					<div class="bottom">
						<div class="left">{{data.ads.value}}</div>
						<div class="right">{{data.ads.value}}</div>
					</div>
				</div>


				<!-- MEMBERSHIP -->
				<div class="membership" :class="{disable: type === 'free'}">
					<div class="top">
						<div class="left"><span>Membership:</span>{{data.membership.value}}</div>
						<div class="right">{{data.membership.value}}</div>
					</div>
					<v-slider
						:disabled="type === 'free'"
						v-model="data.membership.value"
						color="#3C875A"
						thumb-color="#9AAECD"
						:min="data.membership.min"
						:max="data.membership.max"
						:step="data.membership.step"
					></v-slider>
					<div class="bottom">
						<div class="left">{{data.membership.value}}</div>
						<div class="right">{{data.membership.value}}</div>
					</div>
				</div>


				<!-- PPS -->
				<div class="pps" :class="{block: data.pps.disable === true}">
					<div class="top">
						<div class="left"><span>PPSM:</span>{{data.pps.value}}</div>
						<div class="right"><span>PPS:</span>{{data.pps.value}}</div>
					</div>
<!--					{{data.pps.disable}}/-->
<!--					{{shouldDisableLeftSide}}-->
<!--					:readonly="revenue <= 0"-->
					<v-slider
						v-model="data.pps.value"
						color="#81424A"
						thumb-color="#9AAECD"
						:min="data.pps.min"
						:max="data.pps.max"
						:step="data.pps.step"
						@start="ppsChange"
						@input="updateSliderValue"
					></v-slider>
					<div class="bottom">
						<div class="left">{{data.pps.value}}</div>
						<div class="right">{{data.pps.value}}</div>
					</div>
				</div>


				<!-- BANDWIDTH -->
				<div class="bandwidth">
					<template v-if="type === 'free'">
						<div class="top">
							<div class="left"><span>Bandwidth:</span>55</div>
							<div class="right"><span>Cost per GiB:</span>55</div>
						</div>

						<v-slider
							v-model="data.bandwidth.free.value"
							color="#81424A"
							thumb-color="#9AAECD"
							:min="data.bandwidth.free.min"
							:max="data.bandwidth.free.max"
							:step="data.bandwidth.free.step">
						</v-slider>

						<div class="bottom">
							<div class="left">44</div>
							<div class="right">33</div>
						</div>
					</template>
					<template v-if="type === 'paid'">
						<div class="top">
							<div class="left"><span>Bandwidth:</span>55</div>
							<div class="right"><span>Cost per GiB:</span>55</div>
						</div>
						<v-slider
							v-model="data.bandwidth.paid.value"
							color="#81424A"
							thumb-color="#9AAECD"
							:min="data.bandwidth.paid.min"
							:max="data.bandwidth.paid.max"
							:step="data.bandwidth.paid.step">
						</v-slider>
						<div class="bottom">
							<div class="left">44</div>
							<div class="right">33</div>
						</div>
					</template>




				</div>


				<!-- USERS -->
				<div class="users">
					<div class="top">
						<div class="left"><span>Users:</span>{{data.users.value}}</div>
					</div>
					<v-slider
						v-model="data.users.value"
						color="#858585"
						thumb-color="#9AAECD"
						:min="data.users.min"
						:max="data.users.max"
						:step="data.users.step"
					></v-slider>
					<div class="bottom">
						<div class="left">{{data.users.value}}</div>
						<div class="right">600k</div>
					</div>
				</div>
			</div>

			<button type="button" class="average">Average</button>

		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {

			revenue: 0,
			type: 'free',
			profitable: false,
			slider: {
				ads: 0,
				membership: 0,
				pps: 0,
				bandwidth: 0,
				users: 0
			},
			data: {
				ads: {
					min: 0,
					max: 2.4,
					step: 0.1,
					value: 0
				},
				membership: {
					min: 0.99,
					max: 9.99,
					step: 1,
					value: 0
				},
				pps: {
					disable: false,
					min: 0.507,
					max: 4.9,
					step: 0.1,
					value: 0
				},
				bandwidth: {
					free: {
						min: 0.025,
						max: 0.126,
						step: 0.01,
						value: 0
					},
					paid: {
						min: 0.05,
						max: 0.25,
						step: 0.01,
						value: 0
					}
				},
				users: {
					min: 1,
					max: 600000,
					step: 1000,
					value: 0
				}
			}
		}
	},
	watch: {
		type(val) {
			if (val === 'paid') {
				this.data.ads.value = 0;
			} else if (val === 'free') {
				this.data.membership.value = 0;
			}
			this.checkProfitable();
		},
		'data.ads.value'() {
			this.revenueCalc();
		},
		'data.membership.value'() {
			this.revenueCalc();
		},


		'data.pps.value': {
			handler(newVal, oldVal) {
				// if (oldVal < newVal) {
				// 	this.data.pps.disable = true;
				// 	// this.data.pps.value = oldVal;
				// 	// console.log('this.data.pps.value', this.data.pps.value);
				// } else {
				// 	this.data.pps.disable = false;
				// }
				this.revenueCalc();
			},
			deep: true
		},
		// 'data.bandwidth.value'() {
		// 	this.revenueCalc();
		// },
		'data.users.value'() {
			this.revenueCalc();
		}
		// ARTIST: {
		// 	handler() {
		// 		this.getAvatar();
		// 	},
		// 	deep: true
		// }

	},
	computed: {

		// shouldDisableLeftSide() {
		// 	console.log('shouldDisableLeftSide', this.data.pps.value);
		// 	return this.revenue < 0;
		// }
	},
	created() {

	},
	mounted() {

	},
	methods: {
		checkProfitable(ev) {
			console.log('checkProfitable', ev);
		},
		revenueCalc() {
			let result = (this.data.ads.value - this.data.pps.value - this.data.bandwidth.free.value) * this.data.users.value;
			this.revenue = result.toFixed(2);
			this.data.pps.disable = this.revenue <= 0;

		},
		ppsChange() {
			console.log(1);
			// this.data.pps.disable = false;
			// console.log('this.data.pps', this.data.pps.value);
			//
			// let revenue = this.revenue;
			// console.log('revenue', revenue);
			// if (this.revenue > revenue) {
			// 	console.log('>');
			// 	this.data.pps.disable = false;
			// }
			//
			// if (this.revenue <= 0) {
			// 	this.data.pps.disable = true;
			// } else {
			// 	this.data.pps.disable = false;
			// }
		},
		updateSliderValue(newValue) {
			return false;
			console.log(2);
			// if (newValue > this.data.pps.value) {
			// 	console.log('>більше');
			// } else {
			// 	console.log('<менше');
			// }
			if (this.revenue <= 0) {
				// this.data.pps.value = newValue;
				this.data.pps.disable = true;
			} else {
				this.data.pps.disable = false;
			}
			// if (newValue < ) {
			// 	this.data.pps.value = 1.2; // Або інше значення, яке вам потрібно
			// } else {
			// 	this.data.pps.value = newValue;
			// }
		}

	}
}
//profitable

</script>

<style lang="scss">

.custom-slider {
	width: 100%;
	position: relative;
}

.slider-track {
	height: 10px;
	background-color: #ccc;
	width: 100%;
}

.slider-thumb {
	width: 20px;
	height: 20px;
	background-color: #007bff;
	border-radius: 50%;
	position: absolute;
	top: -5px;
	cursor: pointer;
}
////////////////////

.wrapper_calculator {
	margin-top: 100px;
	display: flex;
	justify-content: center;
	margin-bottom: 200px;
}
	.calculator {
		width: 407px;
		//height: 688px;
		border-radius: 20px;
		border: 1px solid #ff00e1;
		padding: 40px 20px;
		.main_input {
			margin-bottom: 20px;
			> label {
				display: flex;
				margin-bottom: 3px;
				font-size: 14px;
				color: #fff;
			}
			> input {
				width: 100%;
				height: 50px;
				border-radius: 10px;
				padding: 0 16px;
				border: 1px solid #B6B6B6;
				background: #D1D1D1;
				box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25) inset;
				color: #000;
				font-size: 18px;
				font-weight: 600;
			}
		}
		.row_buttons {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 32px;
			margin-bottom: 20px;

			.v-input--selection-controls {
				margin: 0;
				padding: 0;
				height: 27px;
			}
			.v-input__slot {
				margin-bottom: 0;
			}
			.v-radio {
				//margin-right: 0;
			}

			.profitable_checkbox {
				> label {
					&:before {
						padding: 10px;
						padding-right: 8px;
					}
				}
			}
		}
		.sliders_calc {
			> div {
				//border: 1px solid #000;
				margin-bottom: 20px;
				width: 100%;
				position: relative;
				.top {
					display: flex;
					justify-content: space-between;
					.left {
						> span {
							font-weight: 700;
							margin-right: 4px;
						}
					}
					.right {
						> span {
							font-weight: 700;
							margin-right: 4px;
						}
					}
				}
				.bottom {
					display: flex;
					justify-content: space-between;
					.left {

					}
					.right {

					}
				}

				.v-slider__thumb {
					width: 19px;
					height: 19px;
					cursor: pointer;
					&:after {
						content: none;
					}
					&:before {
						content: none;
					}
				}
				.v-input__control {
					.v-input__slot {
						margin: 0;
					}
					.v-messages {
						display: none;
					}
				}

				//&.block {
				//	&:after {
				//		content: '';
				//		position: absolute;
				//		top: 0;
				//		left: 0;
				//		width: 100%;
				//		height: 100%;
				//		background: rgba(#000, .5);
				//		z-index: 1;
				//	}
				//}

				.v-slider--horizontal {
					margin: 0;
					.v-slider__track-container {
						height: 8px;
						border-radius: 15px;
						overflow: hidden;
						.v-slider__track-background {
							//background: #3C875A;
						}
					}
				}
				&.ads {
					.v-slider__track-container {
						.v-slider__track-background {
							background: #3C875A;
						}
					}
				}
				&.membership {
					.v-slider__track-container {
						.v-slider__track-background {
							background: #3C875A;
						}
					}
				}
				&.pps {
					.v-slider__track-container {
						.v-slider__track-background {
							background: #81424A;
						}

					}
				}
				&.bandwidth {
					.v-slider__track-container {
						.v-slider__track-background {
							background: #81424A;
						}
					}
				}
				&.users {
					.v-slider__track-container {
						.v-slider__track-background {
							background: #858585;
						}
					}
				}
				&.disable {
					.top, .bottom {
						.left, .right {
							color: rgba(#D1D1D1, .1);
						}
					}
					.v-slider__track-container {
						background: rgba(#D1D1D1, .1);

						.v-slider__track-background {
							background: rgba(#3C875A, .1);
						}
						.v-slider__track-fill {
							background: rgba(#3C875A, .1);
						}
					}
					.v-slider__thumb {
						background-color: #122031 !important;
					}
				}
			}
		}

		button.average {
			width: 100%;
			height: 45px;
			padding: 0 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			font-weight: 500;
			border-radius: 100px;
			background-color: #5F6986;
		}
	}
</style>